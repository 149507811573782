@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Jaro:opsz@6..72&family=Lilita+One&family=Lobster&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Raleway:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rowdies:wght@300;400;700&display=swap');

* {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    font-size: 0.95rem;
    font-family: "Roboto", sans-serif;
}

*::-webkit-scrollbar{
    display: none;
    width: 0px;
}

body {
    background-color: #ededed;
    font-optical-sizing: auto;
    font-style: normal;

}

.custom-header {
    font-family: "Lobster", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.card-title {
    font-family: "Jaro", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}